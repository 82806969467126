import React, { useEffect, useState } from 'react'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const ChartFilter = ({
    filterChart,
    onChangeFilterChart,
    onResetFilter,
    listKiosk
  }) => {

    const [filterDate, setFilterDate] = useState(new Date())
    
    return (
        <div style={{ display: 'flex', alignItems: 'center' }}>
        {/* Select Input */}
        <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
          <div className="me-5 my-1 d-flex align-items-center filter-item">
            <span style={{ fontSize: '12px', marginRight: 10 }}>
              Tên máy:
            </span>
            <select
              id="kt_filter_orders"
              name="orders"
              data-control="select2"
              data-hide-search="true"
              className="w-125px form-select form-select-solid form-select-sm"
              value={filterChart.kioskId}
              onChange={(event) =>
                onChangeFilterChart({
                  kioskId: event.target.value,
                })
              }
            >
              <option value="" selected="selected">
                Tất cả
              </option>
              {listKiosk.map((kiosk) => (
                <option key={kiosk.kioskId} value={kiosk.kioskId}>
                  {kiosk.kioskName}
                </option>
              ))}
            </select>
          </div>
        </div>
      
        {/* Reset Button 1 */}
        <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
          <img
            onClick={() =>{
                onResetFilter()
                console.log(filterChart)
                filterChart.kioskId = ""
            }}
            className="cursor-pointer filter-reset"
            src="/assets/images/reset.png"
            alt="lisence-plate"
            width={30}
          />
        </div>
      
        {/* Date Picker */}
        <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
          <div className="me-5 my-1 d-flex align-items-center filter-item">
            <span style={{ fontSize: '12px', marginRight: 10 }}>
              Ngày giao dịch:
            </span>
            <DatePicker
              className="w-125px"
              dateFormat="dd/MM/yyyy"
              selected={filterDate}
              onChange={(date) => {
                sessionStorage.removeItem('filterDate');
                console.log('onDate picker changed');
                onChangeFilterChart({
                  day: date,
                });
                setFilterDate(date)
              }}
            />
          </div>
        </div>
      
        {/* Reset Button 2 */}
        <div className="card-toolbar my-1 d-flex align-items-center justify-content-center filter-container">
          <img
            onClick={() => 
                {
                sessionStorage.removeItem('filterDate')
                setFilterDate(new Date())
                }}
            className="cursor-pointer filter-reset"
            src="/assets/images/reset.png"
            alt="lisence-plate"
            width={30}
          />
        </div>
      </div>
    
    )
  }
  
  export default ChartFilter
  