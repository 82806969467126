import React from 'react'
import FileSaver from 'file-saver'
import * as XLSX from 'xlsx'
import { toast } from 'react-toastify'

const ExportCSV = ({
  csvData,
  fileName,
  wscols,
  headerTitle,
  headerTranslate
}) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8'
  const fileExtension = '.xlsx'

  const Heading = headerTranslate

  const exportToCSV = (csvData, fileName, wscols) => {
    if (!csvData) {
      toast.warn('Không có dữ liệu để xuất!!', {
        position: 'top-center',
        autoClose: 3000
      })
    }

    // Create a worksheet from the Heading
    const ws = XLSX.utils.json_to_sheet(Heading, {
      header: headerTitle,
      skipHeader: true,
      origin: 0
    })

    // Set column widths
    ws['!cols'] = wscols

    // Add JSON data to the worksheet
    XLSX.utils.sheet_add_json(ws, csvData, {
      header: headerTitle,
      skipHeader: true,
      origin: -1
    })

    const range = XLSX.utils.decode_range(ws['!ref']) // Get the range of the sheet
    for (let row = 1; row <= range.e.r; row++) {
      const cellAddress = XLSX.utils.encode_cell({ r: row, c: 2 }) // Column 2 (index 1)
      if (ws[cellAddress]) {
        ws[cellAddress].t = 'n' // set the type to number
        ws[cellAddress].z = '0' // Apply number format (without decimals)
      }
    }

    const wb = { Sheets: { data: ws }, SheetNames: ['data'] }
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
  }

  return (
    <button
      className="btn btn-success"
      type="button"
      onClick={(e) => exportToCSV(csvData, fileName, wscols)}
    >
      Xuất Excel
    </button>
  )
}

export default ExportCSV

